<template>
  <section class="page-issues">
    <div
      v-if="initializing"
      class="flex flex-col items-center justify-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <template v-if="loading || issues.total">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="issues.to === null || issues.to == issues.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="pb-12"
            @load="fetch"
          >
            <van-cell
              v-for="issue of issues.data"
              :key="`issue-${issue._id}`"
              :is-link="true"
              :to="{
                name: 'public.service.audit.home.view',
                params: { issue: issue._id }
              }"
              class="issue-item"
            >
              <template #title>
                <p class="font-semibold text-black van-multi-ellipsis--l2">
                  {{ issue.content }}
                </p>
              </template>
              <template #label>
                <p class="mt-3">
                  类别：{{ issue.rootCategory }} ({{ issue.subCategory }})
                </p>
                <p class="mt-3">
                  创建时间：{{
                    $dayjs(issue.createdAt).format('YYYY-MM-DD HH:mm:ss')
                  }}
                </p>
                <p class="flex flex-row items-center justify-between mt-2 -mr-4">
                  <span>状态：<van-tag
                    plain
                    :type="
                      issuesStatus[issue.status]
                        ? issuesStatus[issue.status]
                        : 'primary'
                    "
                    class="px-2 py-1"
                  >
                    {{ issue.status }}
                  </van-tag>
                  </span>
                  <span>
                    <span class="inline-block w-12"><i class="far fa-thumbs-up" /> 0</span>
                    <span class="inline-block w-12"><i class="far fa-thumbs-down" /> 0</span>
                  </span>
                </p>
              </template>
            </van-cell>
          </van-list>
          <div class="button-placeholder" />
        </van-pull-refresh>
      </template>
      <van-empty
        v-else
        description="暂无内容"
      >
        <van-button
          round
          type="danger"
          class="mt-3 bottom-button"
          :to="{name: 'public.service.audit.edit'}"
        >
          我有话说
        </van-button>
      </van-empty>
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState } from 'vuex'
export default {
  name: 'PublicServiceAuditHome',
  mixins: [share],
  data() {
    return {
      mode: null,
      from: null,
      loading: true,
      initializing: true,
      refreshing: false,
      issues: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapState('Common/Config', ['issuesStatus', 'issuesStatusName']),
    ...mapState('Common/Credential', ['userInfo'])
  },
  mounted() {
    this.fetch()
    this.disableShareMenu()
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.issues = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let page = (this.issues.current_page ?? 0) + 1

      this.loading = true
      try {
        // eslint-disable-next-line no-console
        console.log('开始请求数据', page)
        const response = await this.tcb.callFunction({
          name: 'property_service',
          data: {
            $url: 'subscriberList',
            page: page
          }
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, response)
        let issues = {
          data: this.issues.data.concat(result.data.data),
          to: this.issues.to + result.data.data.length,
          current_page: page,
          total: result.data.total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', issues)
        this.$set(this, 'issues', issues)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取您的意见和建议' })
        this.issues = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.issue-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
.button-placeholder {
  height: 50px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
